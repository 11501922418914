<template>
  <v-col cols="12" md="4">
    <v-list dense class="mb-4">
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, index) in items"
          :key="item.id"
          :index="index"
          color="primary"
          :to="{ name: 'CategoryShow', params: { slug: item.url } }"
          class="mt-1"
        >
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-col>
</template>
<script>
export default {
  props: ["items"],
};
</script>