<template>
  <v-container>
    <v-card v-if="books && books.length > 0" class="mb-8">
      <v-toolbar color="" class="elevation-0">
        <v-toolbar-title v-if="title">
          <router-link
            :to="{
              name: 'ContestAll',
            }"
            >{{ title }}</router-link
          >
        </v-toolbar-title>
      </v-toolbar>
      <div class="pa-4">
        <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
          <swiper-slide
            v-for="(item, index) in books"
            :key="item.id"
            :index="index"
          >
            <book-small-card :item="item"></book-small-card>
          </swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        </swiper>
        <div
          class="myswiper-button-prev"
          @click="prev"
          slot="button-prev"
        ></div>
        <div
          class="myswiper-button-next"
          @click="next"
          slot="button-next"
        ></div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import { Navigation, Pagination } from 'swiper';
import "swiper/swiper-bundle.css";
// import 'swiper/css/swiper.css'
// Swiper.use([Navigation, Pagination]);
import { mapGetters } from "vuex";

import BookSmallCard from "../book/BookSmallCard.vue";
export default {
  components: { BookSmallCard, Swiper, SwiperSlide },
  // directives: { swiper: directive },
  data: () => ({
    swiperOptions: {
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      //   type: 'progressbar',
      // },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
      slidesPerView: 1,
      spaceBetween: 0,
      // init: false,

      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 6,
        },
        1300: {
          slidesPerView: 8,
        },
      },
      // Some Swiper option/callback...
    },
  }),
  methods: {
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
  },
  props: ["title", "books", "sort"],
  computed: {
    ...mapGetters(["currentUser", "loadPage", "books_top"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>
