var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.books_top && _vm.books_top.length == 0)?_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('no-content',{attrs:{"route-name":"","title":"Произведений нет"}})],1)],1):_vm._e(),(_vm.showHello && _vm.siteSettings && _vm.siteSettings.hello_title)?_c('v-container',{staticClass:"py-0"},[_c('v-card',{staticClass:"hello my-4"},[(_vm.siteSettings.hello_title)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.siteSettings.hello_title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","icon":""},on:{"click":_vm.closeHello}},[_c('v-icon',[_vm._v("$vuetify.icons.close")])],1)],1):_vm._e(),(_vm.siteSettings.hello_body)?_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"hello-left",attrs:{"cols":"12","md":"8"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.siteSettings.hello_body)}}),(!_vm.currentUser)?_c('v-btn',{staticClass:"hello-reg",attrs:{"to":{ name: 'Register' }}},[_vm._v("Присоединиться")]):_vm._e()],1),_c('v-col',{staticClass:"hello-right",attrs:{"cols":"12","md":"4"}},[_c('div',[_c('h3',[_vm._v("В помощь новичку")]),_c('router-link',{attrs:{"to":{
                  name: 'CategoryInfoShow',
                  params: { slug: 'spravka-chitatelyam' },
                }}},[_vm._v("Справка читателям")]),_c('router-link',{attrs:{"to":{
                  name: 'CategoryInfoShow',
                  params: { slug: 'spravka-avtoram' },
                }}},[_vm._v("Справка авторам")])],1)])],1)],1):_vm._e()],1)],1):_vm._e(),(
      _vm.books_top &&
      _vm.books_top.top_contest_winners &&
      _vm.top_settings.show_contest_winners
    )?_c('contest-winners',{attrs:{"books":_vm.books_top.top_contest_winners,"title":"Победители конкурса"}}):_vm._e(),(
      _vm.books_top &&
      _vm.books_top.top_categories &&
      _vm.top_settings.show_top_categories
    )?_c('top-cat',{staticClass:"mt-4",attrs:{"categories":_vm.books_top.top_categories,"title":"Избранные категории"}}):_vm._e(),(_vm.top_settings.top_banner)?_c('div',[_c('v-container',{staticClass:"pb-0"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.top_settings.top_banner)}})])],1):_vm._e(),(_vm.books_top && _vm.books_top.top_featureds)?_c('book-top',{attrs:{"books":_vm.books_top.top_featureds,"title":"Рекомендуем"}}):_vm._e(),(_vm.books_top && _vm.books_top.top_all)?_c('book-top',{attrs:{"books":_vm.books_top.top_all,"sort":"top_all","title":"ТОП в разных жанрах"}}):_vm._e(),(_vm.books_top && _vm.books_top.top_buy)?_c('book-top',{attrs:{"books":_vm.books_top.top_buy,"title":"Бестселлеры"}}):_vm._e(),(_vm.books_top && _vm.books_top.top_best)?_c('book-top',{attrs:{"books":_vm.books_top.top_best,"sort":"popular","title":"Популярное"}}):_vm._e(),_c('banner',{attrs:{"index":"0"}}),(_vm.books_top && _vm.books_top.top_update)?_c('book-top',{attrs:{"books":_vm.books_top.top_update,"sort":"update","title":"Обновления книг"}}):_vm._e(),(_vm.books_top && _vm.books_top.top_newest)?_c('book-top',{attrs:{"books":_vm.books_top.top_newest,"sort":"new","title":"Новинки"}}):_vm._e(),(
      _vm.books_top && _vm.books_top.top_discount && _vm.books_top.top_discount != 'null'
    )?_c('book-top',{attrs:{"books":_vm.books_top.top_discount,"sort":"discount","title":"Сегодняшние скидки"}}):_vm._e(),(
      _vm.books_top &&
      _vm.books_top.books_might_like &&
      _vm.books_top.books_might_like != 'null'
    )?_c('book-top',{attrs:{"books":_vm.books_top.books_might_like,"title":"Вам может понравиться"}}):_vm._e(),(
      _vm.books_top &&
      _vm.books_top.top_libraries &&
      _vm.books_top.top_libraries != 'null'
    )?_c('book-top',{attrs:{"books":_vm.books_top.top_libraries,"sort":"library","title":"Обновления в библиотеке"}}):_vm._e(),_c('banner',{attrs:{"index":"1"}}),_c('site-footer-menu'),_c('partner-dialog',{attrs:{"home":"true"}}),_c('promo-dialog',{attrs:{"home":"true"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }