<template>
  <div class="book-small-card">
    <router-link
      :to="{ name: 'CategoryShow', params: { slug: item.slug } }"
      class="book-image"
    >
      <img v-if="item.image" :src="item.image" />
    </router-link>
    <div class="book-title">
      <router-link
        :to="{ name: 'CategoryShow', params: { slug: item.slug } }"
        >{{ item.title }}</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
};
</script>
