<template>
  <v-card v-if="categories && categories.length > 0" class="mb-8">
    <v-toolbar color="" class="elevation-0">
      <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
    </v-toolbar>
    <div class="pa-4">
      <swiper ref="mySwiper" class="swiper" :options="swiperOptions">
        <swiper-slide
          v-for="(item, index) in categories"
          :key="item.id"
          :index="index"
        >
          <cat-small-card :item="item"></cat-small-card>
        </swiper-slide>
      </swiper>
      <div class="myswiper-button-prev" @click="prev" slot="button-prev"></div>
      <div class="myswiper-button-next" @click="next" slot="button-next"></div>
    </div>
  </v-card>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import CatSmallCard from "./CatSmallCard.vue";
export default {
  components: { CatSmallCard, Swiper, SwiperSlide },
  data: () => ({
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 0,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 6,
        },
        1300: {
          slidesPerView: 8,
        },
      },
    },
  }),
  methods: {
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
  },
  props: ["title", "categories"],
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>
