<template>
  <v-dialog v-model="showDialog" class="text-center" max-width="600">
    <v-card>
      <v-card-title class="headline justify-center">Промокоды!</v-card-title>

      <v-card-text class="text-center">
        До конца мая вы можете сгенерировать любое количество промокодов и
        раздать их вашим читателям, которые до этого покупали у вас книги на
        других порталах.
      </v-card-text>
      <v-card-actions class="justify-space-around">
        <!-- <v-btn :to="{ name: 'PartnerIndex' }" color="green darken-1" text
          >Перейти</v-btn
        > -->

        <v-btn color="red darken-1" text @click="close">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    <script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: ["home"],
  data: () => ({
    showDialog: false,
  }),
  methods: {
    close() {
      this.showDialog = false;
    },
    open() {
      // console.log("open");
      this.showDialog = true;
      if (this.home) {
        localStorage.setItem("show_promo_dialog_at_home", true);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      siteSettings: "siteSettings",
    }),
    isAuthor() {
      if (this.currentUser && this.currentUser.author) {
        return true;
      }
      return false;
    },
  },
  watch: {
    currentUser() {
      if (this.isAuthor && this.home) {
        let showed = true; //Сообщение показывалось
        showed =
          JSON.parse(localStorage.getItem("show_promo_dialog_at_home")) ===
          true;

        // console.log("showed", showed);

        if (!showed) {
          this.open();
        }
      }
    },
  },
  mounted() {},
};
</script>