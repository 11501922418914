<template>
  <div>
    <v-container v-if="books_top && books_top.length == 0">
      <v-row align="center">
        <no-content route-name title="Произведений нет"></no-content>
      </v-row>
    </v-container>
    <v-container
      v-if="showHello && siteSettings && siteSettings.hello_title"
      class="py-0"
    >
      <v-card class="hello my-4">
        <v-card-title v-if="siteSettings.hello_title">
          {{ siteSettings.hello_title }}
          <v-spacer></v-spacer>
          <v-btn text @click="closeHello" color="primary" icon
            ><v-icon>$vuetify.icons.close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text v-if="siteSettings.hello_body">
          <v-row>
            <v-col cols="12" md="8" class="hello-left">
              <div v-html="siteSettings.hello_body"></div>
              <v-btn
                v-if="!currentUser"
                class="hello-reg"
                :to="{ name: 'Register' }"
                >Присоединиться</v-btn
              >
            </v-col>
            <v-col cols="12" md="4" class="hello-right">
              <div>
                <h3>В помощь новичку</h3>
                <router-link
                  :to="{
                    name: 'CategoryInfoShow',
                    params: { slug: 'spravka-chitatelyam' },
                  }"
                  >Справка читателям</router-link
                >
                <router-link
                  :to="{
                    name: 'CategoryInfoShow',
                    params: { slug: 'spravka-avtoram' },
                  }"
                  >Справка авторам</router-link
                >
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <!-- <book-card v-for="(book, index) in books_top" :key="book.id" :book="book" :index="index"></book-card> -->
    <contest-winners
      v-if="
        books_top &&
        books_top.top_contest_winners &&
        top_settings.show_contest_winners
      "
      :books="books_top.top_contest_winners"
      title="Победители конкурса"
    ></contest-winners>
    <top-cat
      class="mt-4"
      v-if="
        books_top &&
        books_top.top_categories &&
        top_settings.show_top_categories
      "
      :categories="books_top.top_categories"
      title="Избранные категории"
    ></top-cat>

    <div v-if="top_settings.top_banner">
      <v-container class="pb-0">
        <div v-html="top_settings.top_banner"></div>
      </v-container>
    </div>

    <book-top
      v-if="books_top && books_top.top_featureds"
      :books="books_top.top_featureds"
      title="Рекомендуем"
    ></book-top>
    <!-- <book-top
        v-if="books_top && books_top.top_all"
        :books="books_top.top_all"
        sort="comments"
        title="ТОП в разных жанрах"
      ></book-top> -->
    <book-top
      v-if="books_top && books_top.top_all"
      :books="books_top.top_all"
      sort="top_all"
      title="ТОП в разных жанрах"
    ></book-top>
    <book-top
      v-if="books_top && books_top.top_buy"
      :books="books_top.top_buy"
      title="Бестселлеры"
    ></book-top>
    <book-top
      v-if="books_top && books_top.top_best"
      :books="books_top.top_best"
      sort="popular"
      title="Популярное"
    ></book-top>
    <banner index="0"></banner>
    <book-top
      v-if="books_top && books_top.top_update"
      :books="books_top.top_update"
      sort="update"
      title="Обновления книг"
    ></book-top>
    <book-top
      v-if="books_top && books_top.top_newest"
      :books="books_top.top_newest"
      sort="new"
      title="Новинки"
    ></book-top>
    <book-top
      v-if="
        books_top && books_top.top_discount && books_top.top_discount != 'null'
      "
      :books="books_top.top_discount"
      sort="discount"
      title="Сегодняшние скидки"
    ></book-top>
    <book-top
      v-if="
        books_top &&
        books_top.books_might_like &&
        books_top.books_might_like != 'null'
      "
      :books="books_top.books_might_like"
      title="Вам может понравиться"
    ></book-top>
    <book-top
      v-if="
        books_top &&
        books_top.top_libraries &&
        books_top.top_libraries != 'null'
      "
      :books="books_top.top_libraries"
      sort="library"
      title="Обновления в библиотеке"
    ></book-top>

    <banner index="1"></banner>

    <site-footer-menu></site-footer-menu>
    <partner-dialog home="true"></partner-dialog>
    <promo-dialog home="true"></promo-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookTop from "./book/BookTop.vue";
import ContestWinners from "./contest/ContestWinners.vue";
import TopCat from "./topcat/TopCat.vue";
import SiteFooterMenu from "./footer/SiteFooterMenu.vue";
import Banner from "./Banner.vue";
import PartnerDialog from "./partner/PartnerDialog.vue";
import PromoDialog from "./partner/PromoDialog.vue";
export default {
  components: {
    BookTop,
    ContestWinners,
    TopCat,
    SiteFooterMenu,
    Banner,
    PartnerDialog,
    PromoDialog,
  },
  data: () => ({}),
  metaInfo() {
    return {
      title: `Читать книги онлайн бесплатно - Bookstab, Букстаб`,
      meta: [
        {
          name: "description",
          content:
            "Самая крупная литературная платформа и библиотека книг. Более 40.000 книг, которые можно бесплатно читать онлайн и скачать. Удобно читать с телефона, есть мобильная версия.",
        },
        {
          property: "og:title",
          content: "Читать книги онлайн бесплатно - Bookstab, Букстаб",
        },
        { property: "og:site_name", content: "Bookstab" },
        {
          property: "og:description",
          content:
            "Самая крупная литературная платформа и библиотека книг. Более 40.000 книг, которые можно бесплатно читать онлайн и скачать. Удобно читать с телефона, есть мобильная версия.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: window.location.href },
      ],
    };
  },
  methods: {
    getBooks() {
      this.$store.dispatch("getBooksTop");
    },
    closeHello() {
      this.$store.commit("SET_CLOSE_HELLO");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      books_top: "books_top",
      top_settings: "top_settings",
      showHello: "showHello",
      siteSettings: "siteSettings",
    }),
  },
  created() {
    this.getBooks();
  },
  mounted() {
    //  this.$refs.partnerDialog.open();
  },
};
</script>
