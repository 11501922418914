<template>
  <!-- <div v-if="banner && !isMobile" class="banner-gorisont"> -->
  <div v-if="banner" class="banner-gorisont">
    <v-container>
      <router-link
        :style="{ backgroundImage: 'url(' + banner.image_format + ')' }"
        class="elevation-4"
        :to="{ name: 'ReaderShow', params: { bookSlug: banner.slug } }"
      >
        <!-- <img :src="banner.image_format" /> -->
      </router-link>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: ["index"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      banners: "banners",
    }),

    banner() {
      if (this.banners && this.banners[this.index]) {
        return this.banners[this.index];
      }
      return null;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
