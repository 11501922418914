<template>
  <v-container>
    <v-card
      v-if="
        siteSettings && siteSettings.footer && siteSettings.footer.length > 0
      "
      class="mb-8 px-3"
    >
      <!-- <v-toolbar color="" class="elevation-0">
        <v-toolbar-title>Категории</v-toolbar-title>
      </v-toolbar> -->
      <v-row>
        <footer-col
          v-for="(item, index) in siteSettings.footer"
          :key="item.id"
          :index="index"
          :items="item.children"
        >
        </footer-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import FooterCol from "./FooterCol.vue";
export default {
  components: { FooterCol },
  data: () => ({}),
  methods: {},
  computed: {
    ...mapGetters(["siteSettings"]),
  },
};
</script>
